import { Flex, Heading, Image, Button } from '@chakra-ui/react'
import NextLink from 'next/link'

import { s3ImageURL } from '@miimosa/common'
import { FullWidthContainer } from '@miimosa/design-system/components'

const TiinaProgram = () => (
  <FullWidthContainer position="relative" mt={10}>
    <Flex
      direction={{ base: 'column', md: 'row' }}
      px={{ base: 5, md: 'inherit' }}
      align="flex-start"
      height={{ base: 'auto', md: '414px' }}
    >
      <Flex flex="1 1 0%" minWidth={{ base: '100%', md: '558px' }}>
        <Image
          alt=""
          src={s3ImageURL('tiina-illu.jpg')}
          width={{ base: '100%', md: '558px' }}
          height={{ base: 'auto', md: '414px' }}
          ml={{ base: 0, md: '-20px' }}
        />
      </Flex>
      <Flex flex="2 1 0%" mt={{ base: 8, md: 0 }} h="full" direction="column" justify="space-between">
        <Heading size="lg">Le programme Tiina</Heading>
        <Heading size="sm" mt={{ base: 8, md: '16' }} maxWidth={{ base: '100%', md: '738px' }}>
          Tiina est un programme d&apos;investissement à impact lancé par la Fondation Daniel et Nina Carasso, en
          partenariat avec MiiMOSA et makesense, pour soutenir l&apos;amorçage de projets innovants en faveur de la
          transition agricole et alimentaire en France.
        </Heading>
        <Heading size="sm" mt="6" maxWidth={{ base: '100%', md: '738px' }}>
          <span style={{ fontWeight: 'bold' }}>
            1 million d&apos;euros seront déployés par la Fondation Daniel et Nina Carasso
          </span>
          , de 2022 à 2025, pour soutenir l&apos;amorçage de{' '}
          <span style={{ fontWeight: 'bold' }}>projets innovants</span>.
        </Heading>
        <NextLink href="/manifest">
          <Button variant="secondary" mt={{ base: 8, md: '4' }} size="md">
            Découvrir le programme
          </Button>
        </NextLink>
      </Flex>
    </Flex>
  </FullWidthContainer>
)

export default TiinaProgram
