import { Flex, Text, Button, Container } from '@chakra-ui/react'
import NextLink from 'next/link'

import { s3ImageURL } from '@miimosa/common'
import { useMountedBreakpoints } from '@miimosa/design-system'
import { Image } from '@miimosa/design-system/components'
import { LogoDanielNina, Logo2, LogoMakesense } from '@miimosa/design-system/components/icons'

const Hero = () => {
  const imageSize = useMountedBreakpoints({ base: 250, md: 300 }, 300)
  return (
    <Container variant="full" position="relative">
      <Image alt="" src={s3ImageURL('bg_tiina_hero.svg')} fill style={{ objectFit: 'cover' }} zIndex={-1} />
      <Container
        variant="boxed"
        alignItems="center"
        py={0}
        mb={{ base: 12, md: 16 }}
        bgColor="white"
        w="auto"
        minW="auto"
      >
        <Image aspectRatio={1} s3 alt="" src={'logos-tiina-hero.svg'} width={imageSize} height={imageSize} />

        <Text
          mt={{ base: 6, md: '10' }}
          fontSize="1.5rem"
          textAlign="center"
          lineHeight="1.75rem"
          as="h1"
          maxW="623px"
          width="100%"
        >
          Programme d’investissement à impact pour l’émergence de solutions innovantes
        </Text>
        <Flex
          mt={{ base: 6, md: '10' }}
          direction={{ base: 'column', md: 'row' }}
          columnGap={20}
          rowGap={8}
          align="center"
          justify="space-between"
        >
          <Flex direction="column" align="center">
            <Text size="2xl">Porteur de projet ?</Text>
            <NextLink href="/candidater">
              <Button mt="4" size="md" variant="primary">
                Découvrir le programme et candidater
              </Button>
            </NextLink>
          </Flex>
          <Flex direction="column" align="center">
            <Text size="2xl">Citoyen ?</Text>
            <NextLink href="/projects">
              <Button mt="4" size="md" variant="primary">
                Découvrir les projets soutenus
              </Button>
            </NextLink>
          </Flex>
        </Flex>
        <Flex
          direction={{ base: 'column', md: 'row' }}
          mt={{ base: 6, md: '10' }}
          align="center"
          columnGap={10}
          rowGap={6}
          justify="center"
        >
          <LogoDanielNina flex="1 1 0%" as="a" href="" width="126px" height="63px" />
          <Logo2 as="a" flex="1 1 0%" href="" width="109px" height="18px" color="miimosa" />
          <LogoMakesense as="a" flex="1 1 0%" href="" width="168px" height="42px" />
        </Flex>
      </Container>
    </Container>
  )
}

export default Hero
