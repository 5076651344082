import { Heading, Button, Text } from '@chakra-ui/react'

import { FullWidthContainer } from '@miimosa/design-system'

const Candidater = () => {
  return (
    <FullWidthContainer py={16} px={5} bgColor="orange_soft">
      <Heading size="lg" textAlign="center">
        Candidater au programme tiina
      </Heading>
      <Text size="2xl" mt={16} textAlign="center">
        Grâce au programme Tiina, vous pouvez bénéficier :
      </Text>
      <Text mt={6} size="xl" textAlign="center">
        - d&apos;un accompagnement privilégié par MiiMOSA pour lancer une collecte en financement participatif,
        <br />- d&apos;un prêt à taux réduit pouvant aller jusqu&apos;à 50 000€ par la FDNC,
        <br />- d&apos;un accompagnement entrepreneurial par makesense
      </Text>
      <Button size="md" variant="primary" as="a" href="/candidater" mt="10">
        Candidater
      </Button>
    </FullWidthContainer>
  )
}

export default Candidater
