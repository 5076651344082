import { Flex, Heading, Text, Grid } from '@chakra-ui/react'

import { FullWidthContainer } from '@miimosa/design-system/components'
import Icon, { IconName } from '@miimosa/design-system/components/Icon'

export const IMPACTS = [
  {
    title: 'Accès à l’alimentation',
    iconName: 'FoodNationalSafetyBig',
    color: 'blue',
    slug: 'food_national_safety',
    description: 'Une alimentation saine et durable accessible pour tous, en particulier pour les plus précaires.',
  },
  {
    title: 'Santé',
    iconName: 'HealthWellBeingBig',
    color: 'orange',
    slug: 'humain_health_and_wellbeing',
    description:
      'Une alimentation sûre, diversifiée, en quantité suffisante, de bonne qualité gustative et nutritionnelle, issue d’une agriculture durable.',
  },
  {
    title: 'Eau',
    iconName: 'WaterBig',
    color: 'blue',
    slug: 'water',
    description: 'Une eau sans risque pour la santé et en quantité suffisante pour tous.',
  },
  {
    title: 'Réduction des déchets',
    iconName: 'WasteReductionBig',
    color: 'orange',
    slug: 'waste_reduction',
    description:
      'Limiter les pertes, les rebuts et les invendus à toutes les étapes de la production, trier et valoriser pour donner une deuxième vie aux déchets.',
  },
  {
    title: 'Bien-être animal',
    iconName: 'AnimalWelfareBig',
    color: 'green',
    slug: 'animal_welfare',
    description: 'Lutte contre la maltraitance animale, et amélioration du bien-être des animaux et des éleveurs.',
  },
  {
    title: 'Énergie',
    iconName: 'RenewableEnergyBig',
    color: 'blue',
    slug: 'renewable_energy',
    description:
      'Optimisation globale des systèmes énergétiques, des besoins individuels et collectifs de services énergétiques à la production d’énergie.',
  },
  {
    title: 'Commerce équitable',
    iconName: 'SocialCohesionBig',
    color: 'blue',
    slug: 'social_cohesion',
    description:
      "Des prix justes pour les producteurs et l'adhésion des consommateurs pour œuvrer au rééquilibrage du pouvoir et du partage de la valeur dans l'économie.",
  },
  {
    title: 'Climat',
    iconName: 'ClimatBig',
    color: 'orange',
    slug: 'climate',
    description: "Limiter le changement climatique et son impact sur l'environnement et la cohésion social.",
  },
  {
    title: 'Biodiversité',
    iconName: 'BiodiversityBig',
    color: 'green',
    slug: 'biodiversity',
    description: 'Lutte pour la préservation et la restauration de la biodiversité dans sa globalité.',
  },
]

const Description = ({
  iconName,
  description,
  color,
  title,
}: {
  iconName: string
  description: string
  color: string
  title: string
}) => {
  return (
    <>
      <Icon name={iconName as IconName} size="xl" color={color} height="100px" />
      <Text size="lg" mt="4" textAlign="center">
        {title}
      </Text>
      <Text size="md" mt="3" px="2" zIndex={1} textAlign="center">
        {description}
      </Text>
    </>
  )
}

const ImpactProjects = () => {
  return (
    <FullWidthContainer position="relative" my="16">
      <Heading size="lg" textAlign="center">
        Des projets à impacts
      </Heading>
      <Grid
        templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' }}
        columnGap={12}
        rowGap={{ base: '40px', md: '40px' }}
        mt={{ base: 8, md: '80px' }}
      >
        {IMPACTS.map(({ title, color, iconName, description }, index) => (
          <Flex
            key={index}
            direction="column"
            align="center"
            px={{ base: 5, md: 'inherit' }}
            width={{ base: '100%', md: '256px' }}
          >
            <Description iconName={iconName} color={color} title={title} description={description} />
          </Flex>
        ))}
      </Grid>
    </FullWidthContainer>
  )
}

export default ImpactProjects
