import { Flex, Text, Heading, Box, Container } from '@chakra-ui/react'
import { FC } from 'react'

import { Carousel, ChevronLeftCircle, ChevronRightCircle } from '@miimosa/design-system/components'
import { CarassoNews } from '@miimosa/directus'

const renderNews = (news: CarassoNews, index: number) => {
  const {
    title,
    project: { slug, project_title },
    date,
    news_description,
  } = news

  const actualDate = new Date(Date.parse(date))

  return (
    <Flex key={index} direction="column" py={5} width={{ base: '100%', md: '830px' }}>
      <Text size="sm" color="orange">
        {actualDate.toLocaleString('fr-FR', { timeZone: 'UTC', year: 'numeric', month: 'numeric', day: 'numeric' })}
      </Text>
      <Heading size="lg" mt="2">
        {project_title}
      </Heading>
      <Text size="2xl" mt="1">
        {title}
      </Text>
      <Text size="xl" mt="3" h="150px" overflow="hidden">
        {news_description}
      </Text>
      <Text color="orange" size="sm" mt="3" textAlign="right" as="a" href={`/projects/${slug}`}>
        Voir le projet
      </Text>
    </Flex>
  )
}

interface Props {
  news: CarassoNews[]
}

const LastNews: FC<Props> = ({ news }) => {
  return (
    <Container variant="full" my={{ base: 10, md: '110px' }} bg={{ base: 'orange_soft', md: 'unset' }}>
      <Heading size="lg" zIndex="1" textAlign="center">
        Dernières actualités des projets
      </Heading>
      <Flex mt={{ base: 10, md: '16' }} position="relative" width="100%" align="center" justify="center">
        <Box
          height="90%"
          position="absolute"
          top="50px"
          left="0"
          width="90%"
          bgColor="orange_soft"
          zIndex="0"
          borderRadius="0px 500px 500px 0px"
          display={{ base: 'none', md: 'unset' }}
        />
        <Carousel
          zIndex="1"
          width={{ base: '100%', md: '830px' }}
          isDark={true}
          draggable
          boxedControls
          SlideComponent={renderNews}
          itemsPerPage={1}
          hasDots={false}
          data={news}
          ArrowLeft={ChevronLeftCircle}
          ArrowRight={ChevronRightCircle}
          navColor="orange"
        />
      </Flex>
    </Container>
  )
}

export default LastNews
