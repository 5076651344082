import { Flex, Text, Container } from '@chakra-ui/react'

import { s3ImageURL } from '@miimosa/common'
import { Image } from '@miimosa/design-system'

const Ambition = () => {
  return (
    <>
      <Container variant="full" position="relative" mt={{ base: 10, md: '110px' }}>
        <Flex
          height="100%"
          position="absolute"
          top="0"
          left="0"
          width={{ base: '100%', md: '80%' }}
          bgColor="blue_soft"
          zIndex="0"
          borderRadius={{ md: '0px 500px 500px 0px' }}
        />
        <Container variant="boxed" position="relative">
          <Flex direction={{ base: 'column', md: 'row' }} align="center" width="100%">
            <Flex direction="column" align="flex-end" flex="2 1 0%">
              <Text size="xl" textAlign="right" width={{ base: '100%', md: '736px' }}>
                “ Tiina s’inscrit dans le cadre de la politique d’investissement à impact de la Fondation Daniel et Nina
                Carasso, complémentaire à sa mission de mécénat. Nous avons pour ambition de faire émerger un écosystème
                d’entreprises innovantes et en capacité de faire bouger les lignes.
                <br />
                Pour ce faire, ce dispositif d&apos;amorçage, porté par la Fondation en partenariat avec MiiMOSA et
                makesense, identifie, finance et accompagne ces innovations contribuant à la transition agricole et
                alimentaire et les aide à se déployer ! ”
              </Text>
              <Text mt="3" textAlign="right" size="md" color="blue">
                Benoît Mounier, Directeur général France de la Fondation Daniel et Nina Carasso
              </Text>
            </Flex>
            <Flex
              direction="column"
              flex="1 1 0%"
              width={{ base: '100%', md: 'inherit' }}
              minHeight="352px"
              position="relative"
              align="center"
              justify="center"
            >
              <Flex
                position={{ base: 'relative', md: 'absolute' }}
                top={{ base: 0, md: '-120px' }}
                left={{ base: 0, md: '32px' }}
                width={{ base: '240px', md: '352px' }}
                height={{ base: '240px', md: '352px' }}
                overflow="hidden"
                borderRadius="50%"
              >
                <Image alt="" src={s3ImageURL('b-mounier.jpg')} fill style={{ objectFit: 'cover' }} />
              </Flex>
            </Flex>
          </Flex>
        </Container>
      </Container>
    </>
  )
}

export default Ambition
