import { captureException } from '@sentry/nextjs'
import { GetStaticProps } from 'next'
import Head from 'next/head'
import { FC } from 'react'

import { carassoNews, type CarassoNews, type CarassoProjectCard, getCarassoProjectCards } from '@miimosa/directus'

import { ImpactProjects, Candidater, UpcomingProjects, Ambition, LastNews, TiinaProgram, Hero } from '@components/home'

const getDirectusNews = async (): Promise<CarassoNews[]> => {
  try {
    const {
      data: { data },
    } = await carassoNews()

    return data
  } catch (err) {
    captureException(err)
    return []
  }
}

const getHomeProjects = async (): Promise<CarassoProjectCard[]> => {
  try {
    const {
      data: { data },
    } = await getCarassoProjectCards({ displayHome: true })

    return data
  } catch (err) {
    captureException(err)
    return []
  }
}

export const getStaticProps: GetStaticProps<Props> = async ({}) => {
  return {
    props: {
      projects: await getHomeProjects(),
      news: await getDirectusNews(),
    },
  }
}

interface Props {
  news: CarassoNews[]
  projects: CarassoProjectCard[]
}

const Home: FC<Props> = ({ projects, news }) => {
  return (
    <>
      <Head>
        <title>tiina - Accueil</title>
      </Head>
      <Hero />
      {!projects?.length && <Candidater />}
      <TiinaProgram />
      <Ambition />
      <ImpactProjects />
      {projects?.length && <UpcomingProjects projects={projects} />}
      <LastNews news={news} />
    </>
  )
}

export default Home
