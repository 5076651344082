import { Heading, Button, Grid } from '@chakra-ui/react'
import { FC } from 'react'

import { FullWidthContainer } from '@miimosa/design-system/components'
import { directusAsset, type CarassoProjectCard } from '@miimosa/directus'

import { ProjectCard } from '@components'

interface Props {
  projects: CarassoProjectCard[]
}

const UpcomingProjects: FC<Props> = ({ projects }) => {
  return (
    <FullWidthContainer position="relative" my="12">
      <Heading size="lg" textAlign="center">
        Découvrez les projets
      </Heading>
      <Grid
        templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' }}
        columnGap={4}
        rowGap={{ base: '40px', md: '100px' }}
        mt={{ base: 8, md: '80px' }}
        mx={{ base: 5, md: 8 }}
      >
        {projects.slice(0, 3).map((project, index) => {
          const {
            dotation_amount: dotationAmount,
            slug,
            photo_porteur,
            photo_projet,
            project_title: title,
            location,
            impact,
            description,
            name,
          } = project

          return (
            <ProjectCard
              key={index}
              slug={slug}
              image={directusAsset(photo_projet)}
              title={title}
              place={location}
              impactData={impact}
              description={description}
              username={name}
              avatar={directusAsset(photo_porteur)}
              dotationAmount={dotationAmount}
            />
          )
        })}
      </Grid>
      <Button mt="8" size="md" as="a" href="/projects" variant="primary">
        Voir tous les projets
      </Button>
    </FullWidthContainer>
  )
}

export default UpcomingProjects
